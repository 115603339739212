import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ProfileService } from './services/profile.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {routes} from "./app.routes";

@Injectable({
  providedIn: 'root',
})
export class ProfileIdGuard implements CanActivate {
  constructor(private router: Router, private profileService: ProfileService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const profileId = this.profileService.profileId;
    const allowedRoles = route.data['roles'] as Array<string>;

    if (!profileId) {
      console.warn('Profile ID is not available. Redirecting...');
      return of(this.router.createUrlTree(['/']));
    }

    // Fetch user role asynchronously
    return this.profileService.getProfileClassification().pipe(
      map((userRole: string) => {
        // Check if the user's role is allowed for the route
        if (allowedRoles && allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
          console.warn(`Access denied. User role: ${userRole}`);
          return this.router.createUrlTree(['/']); // Redirect to a safe page like Home
        }

        // Check and add query parameters as before
        const parsedUrl = this.router.parseUrl(state.url);
        if (parsedUrl && parsedUrl.queryParams['EmployeeId'] === profileId) {
          return true; // Profile ID already exists in query params
        }

        parsedUrl.queryParams['EmployeeId'] = profileId;
        return parsedUrl; // Return the updated UrlTree
      })
    );
  }

  canShowMenu(routePath: string): boolean {
    // Find the route definition for the given path
    const route = routes.find(r => r.path === routePath);

    if (!route || !route.data?.['roles']) {
      return true; // If no roles defined, assume it's public
    }

    const userRole = this.profileService.getProfileClassification(); // Get the current user's role
    return route.data?.['roles'].includes(userRole); // Return true if the user's role is allowed
  }

}
