export const environment = {
  production: false,
  API_URL: 'https://dev.api.itkbm.itksrv.de/customers',
  POST_API_URL: 'https://dev.api.itkbm.itksrv.de/records',
  msalConfig: {
    auth: {
      clientId: '226bbfc8-c3d2-4861-be54-b204979536b1',
      authority: 'https://login.microsoftonline.com/6b0cd246-db9d-4b74-9fe7-4611f4e5694b',
      redirectUri: 'http://localhost:4200',
    },
  },
  apiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
};
