<div class="dropdown-container">
  <mat-form-field appearance="fill">
    <mat-select #customerSelect panelClass="custom-select-panel" [placeholder]="placeholder" id="customerSelect"
                (selectionChange)="onSelectionChange($event)">
      <mat-option *ngFor="let customer of customers" [ngClass]="getClass(customer)" [value]="customer.Tag">
        <div class="customer-info">
          <strong>{{ customer.Tag }}</strong>&nbsp;({{ customer.Number }})
          <!-- Interval Dates -->
          <div class="interval-dates" *ngIf="customer.IntervalStart && customer.IntervalEnd">
            <span class="start-date">{{ customer.IntervalStart | date: 'dd.MM.yy' }}</span>
            <span class="end-date">{{ customer.IntervalEnd | date: 'dd.MM.yy' }}</span>
          </div>

          <!-- Progress Circle -->
          <div class="progress-circle-wrapper" *ngIf="customer.InclusiveVolume !== null && customer.CombinedUsedAndOpenHours !== null">
            <div class="progress-circle" [ngStyle]="{
              '--progress-angle': calculateProgressAngle(customer) + 'deg',
              '--progress-color': calculateColor(customer)
            }">
              <div class="progress-circle-inner">
                {{ customer.InclusiveVolume }} h
              </div>
            </div>

            <!-- Remaining Interval -->
            <div class="customer-interval" *ngIf="customer.RestOfInterval !== null">
              {{ customer.RestOfInterval }}
            </div>
          </div>

          <!-- Customer Hours -->
          <span *ngIf="customer.CombinedUsedAndOpenHours != null" style="margin-left: 5px">
            <i>{{ customer.CombinedUsedAndOpenHours }} h</i>
          </span>
          <span *ngIf="customer.AllCustomerOpenHours != null" style="margin-left: 5px">
            <i>[{{ customer.AllCustomerOpenHours }} h]</i>
          </span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="item-row">
    <!-- File Import -->
    <span>
      <input type="file" id="jsonFileInput" (change)="onImportJSON($event)" style="display: none;" />
      <button (click)="triggerFileInput()" title="Import JSON">
        <i class="fas fa-upload"></i>
      </button>
    </span>

    <!-- Toggle Active/Inactive -->
    <span>
      <button (click)="toggleDataSource()" [title]="useInactive ? 'Inactive Customers' : 'Active Customers'">
        <i class="fas" [ngClass]="useInactive ? 'fa-moon' : 'fa-sun'"></i>
      </button>
    </span>

    <!-- Employee Info -->
    <span class="employee-info">
      {{ employeeDisplayName }}
    </span>
  </div>
</div>
