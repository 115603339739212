<div *ngIf="profile; else unavailable">
  <p><strong>Business Phones:</strong> {{profile.businessPhones || 'Unavailable'}}</p>
  <p><strong>Display Name:</strong> {{profile.displayName || 'Unavailable'}}</p>
  <p><strong>Given Name:</strong> {{profile.givenName || 'Unavailable'}}</p>
  <p><strong>Job Title:</strong> {{profile.jobTitle || 'Unavailable'}}</p>
  <p><strong>Mail:</strong> {{profile.mail || 'Unavailable'}}</p>
  <p><strong>Mobile Phone:</strong> {{profile.mobilePhone || 'Unavailable'}}</p>
  <p><strong>Office Location:</strong> {{profile.officeLocation || 'Unavailable'}}</p>
  <p><strong>Preferred Language:</strong> {{profile.preferredLanguage || 'Unavailable'}}</p>
  <p><strong>Surname:</strong> {{profile.surname || 'Unavailable'}}</p>
  <p><strong>User Principal Name:</strong> {{profile.userPrincipalName || 'Unavailable'}}</p>
  <p><strong>Profile Id:</strong> {{profile.id || 'Unavailable'}}</p>
</div>

<ng-template #unavailable>
  <p>Profile information is not available right now.</p>
</ng-template>

