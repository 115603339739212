import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Customer} from "../interfaces/customer";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private apiUrl: string = environment.API_URL;
  private postApiUrl: string = environment.POST_API_URL;
  private useInactive = false;

  constructor(private http: HttpClient) {}

  getCustomers(): Observable<Customer[]> {
    return this.http.get<Customer[]>(this.apiUrl).pipe(
      map((customers: Customer[]) =>
        customers
          .filter(customer => this.useInactive ? !customer.Active : customer.Active)
          .sort((a, b) => a.Tag.localeCompare(b.Tag))
      )
    );
  }

  saveRecord(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.postApiUrl, payload, { observe: 'response' });
  }

  useInactiveSource(useInactive: boolean) {
    this.useInactive = useInactive;
  }
}
