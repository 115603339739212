<div class="navbar navbar-dark d-flex justify-content-between align-items-center">
  <!-- Burger Menu Button -->
  <button class="burger-menu-button" (click)="menuToggle()">
    <mat-icon>menu</mat-icon>
  </button>

  <!-- Menu Links -->
  <div class="navbar-menu" [class.open]="menuOpen">
    <ng-container *ngFor="let link of (menuLinks$ | async)">
      <a *ngIf="(isMenuVisible(link.path) | async)"
         [routerLink]="link.path"
         class="nav-link"
         [matTooltip]="link.tooltip"
         (click)="handleMenuItemClicked()">
        <mat-icon>{{ link.icon }}</mat-icon>
        <span>{{ link.label }}</span>
      </a>
    </ng-container>
  </div>


  <!-- Center: Logo -->
  <div class="navbar-logo">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.53 77.53" style="height: 45px; fill: white;">
      <defs>
        <style>
          .st1 { fill: white; } /* Changed color to white */
        </style>
      </defs>
      <polygon points="0 22.12 0 25.85 4.92 25.85 4.92 51.11 0 51.11 0 54.84 14.06 54.84 14.06 51.11 9.13 51.11 9.13 25.85 14.06 25.85 14.06 22.12 0 22.12"/>
      <polygon points="18.93 26.34 18.93 22.12 39.06 22.12 39.06 26.34 31.11 26.34 31.11 54.84 26.89 54.84 26.89 26.34 18.93 26.34"/>
      <rect x="48.63" y="17.46" width="4.66" height="42.04"/>
      <polygon points="81.48 54.1 80.12 55.47 64.44 39.91 80.13 24.36 81.49 25.68 71.69 39.91 81.48 54.1"/>
      <path d="M95.46,32.49c-1.45,0-3.25.88-4.13,3.86h7.78c0-2.24-1.37-3.86-3.65-3.86M90.8,40.83c0,2.9,1.67,6.5,5.36,6.5,1.8,0,3.34-.97,4.66-2.54l2.1,2.59c-1.54,2.24-4.26,4-7.16,4-5.93,0-9.49-5.1-9.49-11.37s3.65-11.56,9.1-11.56c5.05,0,8.92,5.14,8.17,11.64h-12.74c0,.27,0,.48,0,.75"/>
      <path d="M123.87,38.83l-2.68-6.02c-2.42.44-4.35,2.63-4.35,5.18v9.54l4.92,2.02v1.59h-14.01v-1.59l4.88-2.02v-14.75l-4.88-2.02v-1.58h9.09v2.24c1.37-1.63,3.47-2.73,5.76-2.73,1.01,0,1.97.22,2.86.57v9.58h-1.59Z"/>
      <path d="M134.81,51.14h-4.22v-21.96h1.58l1.19,2.9c1.27-2.29,3.64-3.39,5.71-3.39,3.61,0,6.46,3.12,6.46,7.86v14.59h-4.22v-14.5c0-2.59-1.36-4.04-3.07-4.04s-3.42,1.54-3.42,3.42v15.13h0Z"/>
      <path class="st1" d="M91.74,57.61c-5.99,7.25-15.54,15.6-28.22,15.6s-22.24-8.35-28.22-15.6l2.47-1.87c4.78,5.44,14.22,13.51,25.75,13.51s20.98-8.07,25.75-13.51l2.47,1.87Z"/>
      <path class="st1" d="M91.74,19.84l-2.47,1.87c-4.78-5.44-14.22-13.51-25.74-13.51s-20.98,8.07-25.75,13.51l-2.47-1.87c5.99-7.25,15.54-15.59,28.23-15.59s22.23,8.35,28.21,15.59"/>
    </svg>
  </div>

  <!-- Right: Profile, Login/Logout -->
  <div class="navbar-right d-flex align-items-center">
    <ng-container *ngIf="loginDisplay; else showLogin">
      <a routerLink="/profile" matTooltip="Profile" class="icon-button">
        <mat-icon>{{ profileIcon }}</mat-icon>
      </a>
      <button class="icon-button" matTooltip="Sign Out" (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </ng-container>
    <ng-template #showLogin>
      <button class="icon-button" matTooltip="Sign In" (click)="login()">
        <mat-icon>login</mat-icon>
      </button>
    </ng-template>
  </div>
</div>

<!-- Content Section -->
<div class="container">
  <router-outlet></router-outlet>
</div>
