import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication, LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';

let msalInstance: IPublicClientApplication | null = null; // Singleton instance

/**
 * Factory for providing MSAL instance as a singleton.
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  // Check and initialize MSAL instance if not already done
  if (!msalInstance) {
    msalInstance = new PublicClientApplication({
      auth: {
        clientId: '226bbfc8-c3d2-4861-be54-b204979536b1', // Replace with your client ID
        authority: 'https://login.microsoftonline.com/6b0cd246-db9d-4b74-9fe7-4611f4e5694b', // Replace with your tenant-specific authority
        redirectUri: 'https://dev.itkbm.itksrv.de/', // Set your redirect URI here
      },
      cache: {
        cacheLocation: 'localStorage', // BrowserCacheLocation.LocalStorage ensures tokens are persisted across browser sessions
        storeAuthStateInCookie: false, // Disable cookie-based storage
      },
      system: {
        // Enable verbose logging for development and debugging
        loggerOptions: {
          logLevel: LogLevel.Verbose, // Log all MSAL events (set to error in production)
          loggerCallback: (level, message, containsPii) => {
            if (!containsPii) { // Exclude personal information in logs
              console.log(`[MSAL ${LogLevel[level]}]: ${message}`);
            }
          },
        },
      },
    });
  }

  return msalInstance;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}
