import { bootstrapApplication } from '@angular/platform-browser';
import { provideHttpClient } from '@angular/common/http';
import { provideRouter } from '@angular/router'; // Import provideRouter
import { AppComponent } from './app/app.component';
import routes from './app/app.routes'; // Import your routes as default export
import '@angular/localize/init';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

const appConfig = {
  providers: [
    provideHttpClient(),  // Provide HttpClient
    provideRouter(routes), provideAnimationsAsync()  // Provide Router with routes
  ],
  // Add any other app configuration options here
};

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
