import { Injectable } from '@angular/core';
import { CustomerService } from './customer.service'; // Import CustomerService
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private _profileId: string | null = null;

  constructor(private customerService: CustomerService) {}

  // Getter for profile ID
  get profileId(): string | null {
    return this._profileId ? this._profileId.toUpperCase() : null;
  }

  // Setter for profile ID
  set profileId(value: string | null) {
    this._profileId = value;
  }

  /**
   * Function to get profile classification (`not registered`, `employee`, `customer`)
   * based on the profile ID using the CustomerService.
   */
  getProfileClassification(): Observable<string> {
    const profileId = this.profileId;
    if (!profileId) {
      throw new Error('Profile ID is null or undefined');
    }
    return this.customerService.getProfileType(profileId); // Call CustomerService mock function
  }
}
