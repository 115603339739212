import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../services/customer.service';
import { Customer } from '../interfaces/customer';
import { Todo } from '../interfaces/todo';
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-todo-manager',
  templateUrl: './todo-manager.component.html',
  styleUrls: ['./todo-manager.component.scss'],
  imports: [
    FormsModule,
    NgIf,
    NgForOf
  ],
  standalone: true
})
export class TodoManagerComponent implements OnInit {
  customers: Customer[] = []; // Alle Kunden
  todos: Todo[] = []; // To-Dos für den ausgewählten Kunden
  selectedCustomerTag: string = ''; // Aktuell ausgewählter Kunde (Tag)
  newTodoTitle: string = ''; // Titel eines neuen To-Dos

  constructor(private customerService: CustomerService) {}

  ngOnInit(): void {
    this.loadCustomers();
  }

  // Kunden vom Server laden
  loadCustomers(): void {
    this.customerService.getCustomers().subscribe((customers) => {
      this.customers = customers;

      // Optional: Wähle den ersten Kunden als Standard
      if (customers.length) {
        this.selectedCustomerTag = customers[0].Tag;
        this.loadTodos();
      }
    });
  }

  // To-Dos für den ausgewählten Kunden laden
  loadTodos(): void {
    // Beispiel: Lade To-Dos aus einem lokalen Speicher (dies könnte später erweitert werden)
    this.todos = JSON.parse(localStorage.getItem(this.selectedCustomerTag) || '[]');
  }

  // Neuen To-Do-Schlüssel generieren (#Tag + 3-stelliger Zähler)
  generateTodoKey(): string {
    const count = this.todos.length + 1;
    return `#${this.selectedCustomerTag}${count.toString().padStart(3, '0')}`;
  }

  // Neues To-Do hinzufügen
  addTodo(): void {
    if (!this.newTodoTitle.trim()) {
      alert('Bitte einen Titel für das To-Do eingeben');
      return;
    }

    // Schlüssel generieren und neues To-Do erstellen
    const newTodo: Todo = {
      key: this.generateTodoKey(),
      title: this.newTodoTitle.trim(),
      completed: false,
      customerId: this.customers.find((c) => c.Tag === this.selectedCustomerTag)?.ContactId || 0,
    };

    // To-Do hinzufügen und speichern
    this.todos.push(newTodo);
    this.saveTodos();

    // Eingabe zurücksetzen
    this.newTodoTitle = '';
  }

  // To-Do löschen
  deleteTodo(key: string): void {
    this.todos = this.todos.filter((todo) => todo.key !== key);
    this.saveTodos();
  }

  // To-Dos speichern (z. B. im lokalen Speicher)
  saveTodos(): void {
    localStorage.setItem(this.selectedCustomerTag, JSON.stringify(this.todos));
  }
}
