import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Customer} from "../interfaces/customer";
import {MatFormField} from "@angular/material/form-field";
import {MatOption, MatSelect, MatSelectChange} from "@angular/material/select";
import {DatePipe, NgClass, NgForOf, NgIf, NgStyle} from "@angular/common";
import { CustomSelectChange } from './interfaces/custom-select-change.model';


@Component({
  selector: 'app-customer-dropdown',
  standalone: true,
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    NgForOf,
    NgIf,
    NgStyle,
    NgClass,
    DatePipe
  ],
  templateUrl: './customer-dropdown.component.html',
  styleUrl: './customer-dropdown.component.scss'
})
export class CustomerDropdownComponent implements OnInit {
  @Input() customers: Customer[] = [];
  @Input() employeeDisplayName: string = "Unknown";
  @Input() placeholder = '-- Select Item --';
  @Output() selectionChanged = new EventEmitter<CustomSelectChange>();
  @Output() importJSONEvent = new EventEmitter<File>();
  @Output() toggleDataSourceEvent = new EventEmitter<void>();
  @ViewChild('customerSelect') customerSelect!: any;

  selectedValue: string | null = null;
  useInactive: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onSelectionChange(event: MatSelectChange): void {
    this.selectionChanged.emit({ ...event, selectRef: this.customerSelect });
  }

  onImportJSON(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      this.importJSONEvent.emit(file); // Emit the selected file to the parent
    }
  }

  triggerFileInput(): void {
    document.getElementById('jsonFileInput')?.click();
  }

  toggleDataSource(): void {
    this.useInactive = !this.useInactive;
    this.toggleDataSourceEvent.emit(); // Emit a void event to notify parent
  }

  calculateProgressAngle(customer: Customer): number {
    if (customer.InclusiveVolume !== null && customer.RestOfInclusiveVolume !== null && customer.CombinedUsedAndOpenHours !== null) {
      if (customer.RestOfInclusiveVolume <= 0) {
        return 360; // 100% rotation in degrees when overshot
      }
      const progressPercentage = customer.CombinedUsedAndOpenHours / customer.InclusiveVolume;
      return progressPercentage * 360; // Calculate the progress angle between 0 and 360 degrees
    }
    return 0;
  }

  calculateColor(customer: Customer): string {
    const root = getComputedStyle(document.documentElement);
    return this.isOvershot(customer) ? root.getPropertyValue('--red-darkest').trim() : root.getPropertyValue('--green-medium').trim();
  }

  isOvershot(customer: Customer): boolean {
    return customer.RestOfInclusiveVolume !== null && customer.RestOfInclusiveVolume < 0;
  }

  getClass(customer: Customer) {
    if (customer.InclusiveVolume !== null && customer.RestOfInclusiveVolume !== null) {
      if (customer.RestOfInclusiveVolume === 0) {
        return 'green-option';
      } else if (customer.RestOfInclusiveVolume > 0) {
        return 'orange-option';
      } else {
        return 'red-option';
      }
    }
    return '';
  }
}
