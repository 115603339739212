import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { routes } from './app/app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  MsalService,
  MsalBroadcastService,
  MsalGuard,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory } from './app/app.factory';

import { IPublicClientApplication } from '@azure/msal-browser';

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes),
    provideAnimationsAsync(),
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService, provideAnimationsAsync(),
  ],
})
  .then(async (appRef) => {
    const msalInstance = appRef.injector.get<IPublicClientApplication>(MSAL_INSTANCE);

    try {
      console.log('Initializing MSAL...');

      // Initialize MSAL
      await msalInstance.initialize();
      console.log('MSAL instance initialized successfully.');

      // Handle redirect responses
      const result = await msalInstance.handleRedirectPromise();

      if (result) {
        console.log('Redirect promise handled:', result);

        // Set active account if available after a successful login or token refresh
        if (result.account) {
          msalInstance.setActiveAccount(result.account); // Set active account for easier access
        }
      } else {
        console.log('No redirect result to handle.');
      }

      // Update the login display state (checks for active accounts)
      const accounts = msalInstance.getAllAccounts();
      if (accounts && accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]); // Set the first account as active
      }

      console.log('Bootstrap process completed.');
    } catch (e) {
      console.error('Error during MSAL initialization:', e);
    }
  })
  .catch((err) => console.error('Bootstrap error:', err));
