// Required for Angular
import { Component, OnInit } from '@angular/core';

// Required for the HTTP GET request to Graph
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Required imports for MSAL
import { MsalService } from '@azure/msal-angular';
import {NgIf} from "@angular/common";

type ProfileType = {
  businessPhones?: string,
  displayName?: string,
  givenName?: string,
  jobTitle?: string,
  mail?: string,
  mobilePhone?: string,
  officeLocation?: string,
  preferredLanguage?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  profile!: ProfileType;
  tokenExpiration!: string;

  constructor(
    private http: HttpClient,
    private authService: MsalService // Inject MSAL service for authentication
  ) {}

  ngOnInit() {
    // Check for an active account
    const activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount) {
      console.error('No active account! Please log in.');
      this.authService.loginPopup({ scopes: ['User.Read'] })
        .subscribe({
          next: () => this.getProfileDetails(), // Call profile API after login
          error: (error) => console.error('Login failed:', error)
        });
    } else {
      this.getProfileDetails(); // Proceed to call API directly
    }

    this.tokenExpiration = localStorage.getItem('tokenExpiration')!;
  }

  private getProfileDetails() {
    // Acquire an access token silently
    this.authService.acquireTokenSilent({
      account: this.authService.instance.getActiveAccount() ?? undefined,
      scopes: ['User.Read']
    }).subscribe({
      next: (response) => {
        console.log('Token acquired:', response.accessToken);
        this.fetchProfile(response.accessToken); // Use token to fetch profile
      },
      error: (error) => {
        console.error('Silent token acquisition failed; attempting popup.', error);

        // Acquire token via popup as fallback
        this.authService.acquireTokenPopup({ scopes: ['User.Read'] })
          .subscribe({
            next: (response) => {
              console.log('Token acquired via popup:', response.accessToken);
              this.fetchProfile(response.accessToken); // Use token to fetch profile
            },
            error: (popupError) => {
              console.error('Popup token acquisition failed:', popupError);
            }
          });
      }
    });
  }

  private fetchProfile(token: string) {
    // Include the access token in the Authorization header
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    this.http.get<ProfileType>('https://graph.microsoft.com/v1.0/me', { headers })
      .subscribe(
        (profile) => {
          this.profile = profile;
          console.log('Profile fetched:', profile);
        },
        (error) => {
          console.error('Error fetching profile:', error);
        }
      );
  }
}
