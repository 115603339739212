import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe, DecimalPipe, NgClass, NgForOf, NgIf, NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
import 'moment-timezone';
import {saveAs} from 'file-saver';
import {CustomerService} from '../services/CustomerService';
import {catchError, finalize, of, tap} from "rxjs";
import {Customer} from "../interfaces/customer";
import {SaleItem} from "../interfaces/salte-item";
import {Task} from "../interfaces/task";
import {MatFormField} from "@angular/material/form-field";
import {MatOption, MatSelect, MatSelectChange} from "@angular/material/select";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {NgxGaugeModule} from "ngx-gauge";
import {MatTooltip} from "@angular/material/tooltip";

type CustomerStatus = 'playing' | 'paused' | 'stopped';

@Component({
  selector: 'app-customer-dropdown',
  standalone: true,
  imports: [NgForOf, FormsModule, NgClass, NgIf, DecimalPipe, NgStyle, MatFormField, MatSelect, MatOption, MatIcon, MatIconButton, NgxGaugeModule, MatTooltip, DatePipe], // Removed provideHttpClient here
  templateUrl: './customer-dropdown.component.html',
  styleUrls: ['./customer-dropdown.component.scss'],
  providers: [CustomerService]
})
export class CustomerDropdownComponent implements OnInit {
  customers: Customer[] = [];
  selectedCustomers: any[] = [];
  useInactive: boolean = false;
  isRequestInProgress: boolean = false;

  employees: Array<{ id: string; name: string }> = [
    {id: 'DF4634F7-5507-4E96-BD6A-7B70FC2BCA9D', name: 'Danny Stephan'},
    {id: 'C3C90AAA-E0D2-4C12-A5BA-2805DC4DBBAC', name: 'Jonas Kern'},
  ];

  employeeId: string | null = null;
  employeeDisplayName: string | null = null;

  statusOrder: Record<CustomerStatus, number> = {playing: 1, paused: 2, stopped: 3};

  constructor(private route: ActivatedRoute, private router: Router, private customerService: CustomerService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.employeeId = params['EmployeeId'];
      this.employeeDisplayName = this.getEmployeeDisplayName(this.employeeId);
      if (this.employeeId) {
        this.loadStateForEmployee(this.employeeId);
        this.sortCustomers();
      }
    });

    if (this.employeeId) {
      this.sortCustomers();
    }

    this.fetchCustomers();
  }

  fetchCustomers(): void {
    this.customerService.getCustomers().subscribe({
      next: (data: Customer[]) => {
        this.customers = data;
      },
      error: (error: any) => {
        console.error('Error fetching customer data', error);
      }
    });
  }

  toggleDataSource() {
    this.useInactive = !this.useInactive;  // Toggle the state
    this.customerService.useInactiveSource(this.useInactive);
    this.fetchCustomers();  // Reload customers from the new data source
  }

  downloadCustomerJson(index: number) {
    const customer = this.selectedCustomers[index];
    const firstTaskStartTime = customer.tasks[0].startTime;
    const fileName = `${moment(firstTaskStartTime).format('yyyy-MM-DD_HH-mm-ss')}_${customer.name}.json`;

    const payload = {
      EmployeeId: this.employeeId,
      EmployeeDisplayName: this.employeeDisplayName,
      Customer: customer.name,
      PrivateCar: customer.privateCar,
      Paused: customer.paused,
      Stopped: customer.stopped,
      Tasks: customer.tasks.map((task: Task) => ({
        TaskId: task.taskId,
        Created: task.startTime,
        Duration: task.duration,
        Description: task.comment,
        OneSite: task.onsite,
        Charge: task.charge,
        Editable: task.editable,
        CommentError: task.commentError,
      })),
      SaleItems: customer.saleItems.map((item: SaleItem) => ({
        Date: item.date,
        Amount: item.amount,
        Price: item.price,
        ProfitFactor: item.profitFactor,
        SalePrice: item.salePrice,
        Postage: item.postage,
        PostagePrice: item.postagePrice,
        Description: item.description,
        IsGrossNet: item.isGrossNet,
      })),
    };

    const blob = new Blob([JSON.stringify(payload, null, 2)], {type: 'application/json'});
    saveAs(blob, fileName);
  }

  triggerFileInput() {
    document.getElementById('jsonFileInput')?.click();
  }

  importJSON = (event: Event) => {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const contents = e.target?.result;
          console.log('File Loaded:', contents);

          if (contents && typeof contents === 'string') {
            const importedJson = JSON.parse(contents);
            console.log('Parsed JSON:', importedJson);

            if (importedJson.EmployeeId === this.employeeId) {
              console.log('JSON matches the employee ID');
              this.selectedCustomers.push({
                name: importedJson.Customer,
                tasks: importedJson.Tasks.map((task: any) => ({
                  taskId: task.TaskId,
                  startTime: task.Created,
                  duration: task.Duration,
                  editable: task.Editable,
                  comment: task.Description,
                  onsite: task.OneSite,
                  charge: task.Charge,
                  commentError: task.CommentError,
                })),
                saleItems: importedJson.SaleItems.map((item: any) => ({
                  date: item.Date,
                  postage: item.Postage,
                  postagePrice: item.PostagePrice,
                  amount: item.Amount,
                  price: item.Price,
                  profitFactor: item.ProfitFactor,
                  salePrice: item.SalePrice,
                  description: item.Description,
                  isGrossNet: item.IsGrossNet
                })),
                paused: importedJson.Paused,
                privateCar: importedJson.PrivateCar,
                stopped: importedJson.Stopped,
                overallCommentError: '',
              });

              console.log('Updated State:', this.selectedCustomers);
              this.saveState();
            } else {
              alert('Cannot import JSON for a different employee.');
            }
          } else {
            console.error('File contents are not valid JSON.');
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        } finally {
          // Resetting the file input value
          if (fileInput) {
            fileInput.value = '';
          }
        }
      };

      reader.onerror = (error) => {
        console.error('File reading error:', error);
        // Resetting the file input value
        if (fileInput) {
          fileInput.value = '';
        }
      };

      reader.readAsText(file);
    } else {
      console.error('No file selected or file input is not valid.');
      // Resetting the file input value
      if (fileInput) {
        fileInput.value = '';
      }
    }
  }

  selectEmployee(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const employeeId = selectElement.value;

    if (employeeId) {
      this.router.navigate([], {
        queryParams: {EmployeeId: employeeId},
        queryParamsHandling: 'merge',
      }).then(() => {
        this.employeeId = employeeId;
        this.employeeDisplayName = this.getEmployeeDisplayName(employeeId);

        const cachedData = this.loadStateForEmployee(employeeId);
        if (cachedData) {
          this.selectedCustomers = cachedData.selectedCustomers;
        } else {
          this.selectedCustomers = [];
        }

        this.saveState();
      });
    }
  }

  getEmployeeDisplayName(employeeId: string | null): string | null {
    const employee = this.employees.find(emp => emp.id === employeeId);
    return employee ? employee.name : null;
  }

  addCustomer(event: MatSelectChange, selectComponent: MatSelect): void {
    const customerTag = event.value;
    const selectedCustomer = this.customers.find(c => c.Tag === customerTag);

    if (selectedCustomer) {
      const taskId = uuidv4();
      const now = moment().tz('Europe/Berlin').format();
      this.selectedCustomers.push({
        name: selectedCustomer.Tag,
        tasks: [
          {
            taskId,
            startTime: now,
            duration: '',
            editable: true,
            comment: '',
            commentError: '',
            onsite: false,
            charge: true
          }
        ],
        saleItems: [],
        paused: false,
        privateCar: false,
        stopped: false,
        overallCommentError: ''
      });
      this.sortCustomers();
      this.saveState();
    }

    selectComponent.value = null;
  }

  private sortCustomers() {
    this.selectedCustomers.sort((a, b) => {
      const statusA = this.getStatus(a);
      const statusB = this.getStatus(b);
      return this.statusOrder[statusA] - this.statusOrder[statusB];
    });
  }

  private getStatus(customer: any): CustomerStatus {
    if (customer.stopped) {
      return 'stopped';
    }
    if (customer.paused) {
      return 'paused';
    }
    return 'playing';
  }

  getClass(customer: Customer) {
    if (customer.InclusiveVolume !== null && customer.RestOfInclusiveVolume !== null) {
      if (customer.RestOfInclusiveVolume === 0) {
        return 'green-option';
      } else if (customer.RestOfInclusiveVolume > 0) {
        return 'orange-option';
      } else {
        return 'red-option';
      }
    }
    return '';
  }

  calculateProgressAngle(customer: Customer): number {
    if (customer.InclusiveVolume !== null && customer.RestOfInclusiveVolume !== null && customer.CombinedUsedAndOpenHours !== null) {
      if (customer.RestOfInclusiveVolume <= 0) {
        return 360; // 100% rotation in degrees when overshot
      }
      const progressPercentage = customer.CombinedUsedAndOpenHours / customer.InclusiveVolume;
      return progressPercentage * 360; // Calculate the progress angle between 0 and 360 degrees
    }
    return 0;
  }

  isOvershot(customer: Customer): boolean {
    return customer.RestOfInclusiveVolume !== null && customer.RestOfInclusiveVolume < 0;
  }

  calculateColor(customer: Customer): string {
    return this.isOvershot(customer) ? 'red' : 'green';
  }

  getCardClass(customer: any): string {
    if (customer.stopped) {
      return 'stopped';
    }
    if (customer.paused) {
      return 'paused';
    }
    return 'playing';
  }

  togglePausePlayCustomer(index: number): void {
    const customer = this.selectedCustomers[index];
    const lastTask = customer.tasks[customer.tasks.length - 1];

    if (customer.stopped) { // Use the old logic for stopped state
      const now = moment().tz('Europe/Berlin').format();
      const taskId = uuidv4();
      customer.tasks.push({
        taskId,
        startTime: now,
        duration: '',
        editable: true,
        comment: '',
        commentError: '',
        onsite: false,
        charge: true,
      });
      customer.paused = false;
      customer.stopped = false;
    } else if (customer.paused) { // Use the new logic for paused state
      const now = moment();
      const durationMs = parseFloat(lastTask.duration) * 3600000; // Convert hours to milliseconds
      lastTask.startTime = now.subtract(durationMs, 'milliseconds').tz('Europe/Berlin').format();
      lastTask.editable = true;
      customer.paused = false;
    } else { // Pausing logic
      if (lastTask.comment.length >= 10) {
        const now = new Date();
        lastTask.duration = this.calculateDuration(lastTask.startTime, now);
        console.log('Paused Customer:', customer, 'Duration:', lastTask.duration);
        lastTask.editable = false;
        customer.paused = true;
      }
    }
    this.sortCustomers();
    this.saveState();
  }

  stopCustomer(index: number): void {
    const customer = this.selectedCustomers[index];
    this.stopCustomerTasks(customer);
    this.sortCustomers();
    this.saveState();
  }

  private stopCustomerTasks(customer: any): void {
    const lastTask = customer.tasks[customer.tasks.length - 1];
    const now = new Date();

    if (lastTask.comment.length >= 10) {
      if (!customer.paused) {
        lastTask.duration = this.calculateDuration(lastTask.startTime, now);
        console.log('Stopped Customer:', customer, 'Duration:', lastTask.duration);
      }
      customer.paused = true;
      customer.stopped = true;
      lastTask.commentError = '';

      customer.tasks.forEach((task: any) => {
        task.editable = true;
      });
    } else {
      lastTask.commentError = 'Each task comment must be at least 10 characters long.';
    }
  }

  discardCustomer(index: number): void {
    const confirmation = confirm('Are you sure you want to cancel this record?');
    if (confirmation) {
      this.selectedCustomers.splice(index, 1);
      console.log(`Discarded customer at index ${index}`);
      this.sortCustomers();
      this.saveState();
    } else {
      console.log('Cancel discard action.');
    }
  }

  saveCustomer(index: number): void {
    const customer = this.selectedCustomers[index];

    if (!customer.stopped) {
      this.stopCustomerTasks(customer);
    }

    const payload = {
      EmployeeId: this.employeeId,
      EmployeeDisplayName: this.employeeDisplayName,
      Customer: customer.name,
      PrivateCar: customer.privateCar,
      Tasks: customer.tasks.map((task: Task) => ({
        TaskId: task.taskId,
        Created: task.startTime,
        Duration: task.duration,
        Description: task.comment,
        Onsite: task.onsite,
        Charge: task.charge,
      })),
      SaleItems: customer.saleItems.map((item: SaleItem) => ({
        Date: item.date,
        Amount: item.amount,
        Price: item.price,
        ProfitFactor: item.profitFactor,
        Postage: item.postage,
        Description: item.description,
      }))
    };

    console.log('Sending the following data to API:', payload);
    this.isRequestInProgress = true; // Lock the save button

    this.customerService.saveRecord(payload).pipe(
      tap((response) => {
        if (response.status === 201) {
          this.selectedCustomers.splice(index, 1);
          this.sortCustomers();
          this.saveState();
        } else {
          confirm(`Received response:\n\n${JSON.stringify(response.body, null, 2)}`);
        }
      }),
      catchError((error) => {
        console.error('API error:', error);
        confirm(`An error occurred:\n\n${JSON.stringify(error.error, null, 2)}`);
        confirm(`API send data:\n\n${JSON.stringify(payload, null, 2)}`);
        return of(null);  // Return empty observable to continue the flow
      }),
      finalize(() => {
        console.log('API request handling finalized.');
        this.isRequestInProgress = false; // Unlock the save button
        this.fetchCustomers();
      })
    ).subscribe();
  }

  calculateDuration(startTime: string, endTime: Date): string {
    const start = new Date(startTime);

    if (isNaN(start.getTime()) || isNaN(endTime.getTime())) {
      return '';
    }

    const diffMs = endTime.getTime() - start.getTime();
    const diffHrs = diffMs / (1000 * 60 * 60);

    const formattedDuration = Math.max(diffHrs, 5e-18).toFixed(18);

    console.log(
      `Calculating Duration - Start Time: ${start}, End Time: ${endTime}, Difference in Hours: ${formattedDuration}`
    );

    return formattedDuration;
  }

  // Functions for sale items
  addSaleItem(index: number): void {
    const now = moment().tz('Europe/Berlin').format();
    const customer = this.selectedCustomers[index];
    customer.saleItems.push({
      date: now,
      postage: 0,
      postagePrice: 0,
      profitFactor: 1.25,
      salePrice: 0,
      amount: 1,
      price: 0,
      description: '',
      isGrossNet: true,
    });
    this.saveState();
  }

  removeSaleItem(customerIndex: number, saleItemIndex: number): void {
    const customer = this.selectedCustomers[customerIndex];
    customer.saleItems.splice(saleItemIndex, 1);
    this.saveState();
  }

  calcGrossNetNetGross(
    customerIndex: number,
    saleItemIndex: number
  ): void {
    const customer = this.selectedCustomers[customerIndex];
    let saleItem = customer.saleItems[saleItemIndex];

    saleItem.isGrossNet = !saleItem.isGrossNet

    if (!saleItem.isGrossNet) {
      saleItem.price = parseFloat((saleItem.price / 1.19).toFixed(2));
    } else {
      saleItem.price = parseFloat((saleItem.price * 1.19).toFixed(2));
    }

    this.onPriceChange(saleItem);
    this.saveState();
  }

  saveState(): void {
    if (this.employeeId) {
      const state = {
        employeeDisplayName: this.employeeDisplayName,
        selectedCustomers: this.selectedCustomers
      };
      localStorage.setItem(`customerDropdownState_${this.employeeId}`, JSON.stringify(state));
    }
  }

  onPriceChange(saleItem: any): void {
    if (saleItem.price !== 0) {
      saleItem.profitFactor = 1.25;
      this.calculateShippingPricePerItem(saleItem);
      saleItem.salePrice = parseFloat((saleItem.postagePrice * saleItem.profitFactor).toFixed(6));
    } else {
      saleItem.salePrice = 0;
    }
    this.saveState();
  }

  onSalePriceChange(saleItem: any): void {
    if (saleItem.price !== 0) {
      this.calculateShippingPricePerItem(saleItem);
      saleItem.profitFactor = parseFloat((saleItem.salePrice / saleItem.postagePrice).toFixed(6));
    } else {
      saleItem.profitFactor = 1.25;
    }
    this.saveState();
  }

  onProfitFactorChange(saleItem: any): void {
    if (saleItem.price !== 0) {
      this.calculateShippingPricePerItem(saleItem);
      saleItem.salePrice = parseFloat((saleItem.postagePrice * saleItem.profitFactor).toFixed(6));
    }
    this.saveState();
  }

  private calculateShippingPricePerItem(saleItem: any) {
    saleItem.postagePrice = saleItem.price + (saleItem.postage / saleItem.amount);
  }

  private loadStateForEmployee(employeeId: string): any {
    const savedState = localStorage.getItem(`customerDropdownState_${employeeId}`);
    if (savedState) {
      const state = JSON.parse(savedState);
      this.employeeDisplayName = state.employeeDisplayName;
      this.selectedCustomers = state.selectedCustomers || [];
      return state;
    }
    return null;
  }

  togglePrivateCar(index: number): void {
    const customer = this.selectedCustomers[index];
    customer.privateCar = !customer.privateCar;
    console.log(`Private car for ${customer.name}: ${customer.privateCar}`);
    this.setFirstTaskToOnsiteTrue(index);
    this.saveState();
  }

  setFirstTaskToOnsiteTrue(index: number) {
    const task = this.selectedCustomers[index].tasks[0];
    task.onsite = true;
  }

  toggleOnsiteCustomer(customerIndex: number, taskIndex: number): void {
    const task = this.selectedCustomers[customerIndex].tasks[taskIndex];
    task.onsite = !task.onsite;
    this.saveState();
  }

  toggleChargeToCustomer(customerIndex: number, taskIndex: number): void {
    const task = this.selectedCustomers[customerIndex].tasks[taskIndex];
    task.charge = !task.charge;
    this.saveState();
  }

  confirmRemoveTask(customerIndex: number, taskIndex: number): void {
    const customer = this.selectedCustomers[customerIndex];

    if (customer.tasks.length === 1) {
      return;
    }

    const confirmation = confirm('Are you sure you want to remove this task?');
    if (confirmation) {
      this.removeTask(customerIndex, taskIndex);
      if (!customer.paused && !customer.stopped)
        this.togglePausePlayCustomer(customerIndex);
      if (customer.privateCar)
        this.setFirstTaskToOnsiteTrue(customerIndex);
    }
  }

  removeTask(customerIndex: number, taskIndex: number): void {
    const customer = this.selectedCustomers[customerIndex];
    customer.tasks.splice(taskIndex, 1);
    this.saveState();
    console.log(`Removed task ${taskIndex} for customer ${customer.name}`);
  }

  checkSaleItemsValidity(customerIndex: number) {
    const customer = this.selectedCustomers[customerIndex];
    for (const saleItem of customer.saleItems) {
      if (saleItem.amount <= 0 || saleItem.price <= 0 || saleItem.salePrice <= 0 || saleItem.profitFactor <= 1 || saleItem.description.length < 10) {
        return false;
      }
    }
    return true;
  }
}
