import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CustomerDropdownComponent } from './customer-dropdown/customer-dropdown.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CustomerDropdownComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']  // Correct the property name
})
export class AppComponent {
  title = 'itkern-business-manager-ui';
}
