<div class="dropdown-container">
  <ng-container *ngIf="!employeeId; else customerComponents">
    <select id="employeeSelect" (change)="selectEmployee($event)">
      <option value="">-- Select Employee --</option>
      <option *ngFor="let employee of employees" [value]="employee.id">{{ employee.name }}</option>
    </select>
  </ng-container>
</div>

<ng-template #customerComponents>
  <div class="customer-dropdown">
    <mat-form-field appearance="fill">
      <mat-select #customerSelect  panelClass="custom-select-panel" placeholder="-- Select Customer --" id="customerSelect"
                  (selectionChange)="addCustomer($event, customerSelect)">
        <mat-option *ngFor="let customer of customers" [ngClass]="getClass(customer)" [value]="customer.Tag">
          <div class="customer-info">
            <strong>{{ customer.Tag }}</strong>&nbsp;({{ customer.Number }})
            <div class="progress-circle-wrapper" *ngIf="customer.InclusiveVolume !== null && customer.CombinedUsedAndOpenHours !== null">
              <div class="interval-dates">
                <span class="start-date">{{ customer.IntervalStart | date: 'dd.MM.yy' }}</span>
                <span class="end-date">{{ customer.IntervalEnd | date: 'dd.MM.yy' }}</span>
              </div>
              <div class="progress-circle" [ngStyle]="{
         '--progress-angle': calculateProgressAngle(customer) + 'deg',
         '--progress-color': calculateColor(customer)
       }">
                <div class="progress-circle-inner">
                  {{ customer.InclusiveVolume }} h
                </div>
              </div>
              <div class="customer-intervall">
                {{ customer.RestOfInterval}}
              </div>
            </div>
            <span *ngIf="customer.CombinedUsedAndOpenHours != null" style="margin-left: 5px">
              <i>{{ customer.CombinedUsedAndOpenHours }} h</i>
            </span>
            <span *ngIf="customer.AllCustomerOpenHours != null" style="margin-left: 5px">
              <i>[{{ customer.AllCustomerOpenHours }} h]</i>
            </span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="item-row">
    <span>
      <input type="file" id="jsonFileInput" (change)="importJSON($event)" style="display: none;"/>
      <button (click)="triggerFileInput()" title="Import JSON">
        <i class="fas fa-upload"></i>
      </button>
    </span>
      <span>
      <button (click)="toggleDataSource()" [title]="useInactive ? 'Inactive Customers' : 'Active Customers'">
        <i class="fas" [ngClass]="useInactive ? 'fa-moon' : 'fa-sun'"></i>
      </button>
    </span>
      <span *ngIf="employeeDisplayName" class="employee-info">
      {{ employeeDisplayName }}
    </span>
    </div>
  </div>

  <div class="card-list">
    <div class="card" *ngFor="let customer of selectedCustomers; let i = index" [ngClass]="getCardClass(customer)">
      <div class="card-header">
        <h2>{{ customer.name }}</h2>
        <button
          (click)="togglePrivateCar(i)"
          [title]="customer.privateCar ? 'Private Car' : 'Business Car'"
          class="private-car-button">
          <i class="fas" [ngClass]="customer.privateCar ? 'fa-car-alt orange-icon' : 'fa-car'"></i>
        </button>
      </div>
      <table class="time-table">
        <thead>
        <tr>
          <th>Start Time</th>
          <th>Duration</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let task of customer.tasks; let j = index">
          <tr>
            <td style="border-right: none; border-bottom: none">
              <input type="text" [(ngModel)]="task.startTime" [readonly]="!task.editable" (ngModelChange)="saveState()">
            </td>
            <td style="border-left: none; border-bottom: none" class="time-table-duration">
              <input type="text" [(ngModel)]="task.duration" [readonly]="!task.editable" (ngModelChange)="saveState()">
              <button
                title="Cancel record task"
                *ngIf="customer.stopped || customer.paused || (j === customer.tasks.length - 1 && customer.tasks[customer.tasks.length - 1].comment.length < 10)"
                class="cancel-task-button"
                (click)="confirmRemoveTask(i, j)"
                [disabled]="customer.tasks.length === 1">
                <i class="fas fa-times"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="border-top: none">
              <div class="textarea-wrapper">
                <textarea [(ngModel)]="task.comment" placeholder="Add a comment..." (ngModelChange)="saveState()"
                          [readonly]="!task.editable"></textarea>
                <button class="onsite-button" (click)="toggleOnsiteCustomer(i, j)"
                        [title]="task.onsite ? 'On site' : 'Remote'">
                  <i class="fas" [ngClass]="task.onsite ? 'fa-map-marker-alt' : 'fa-map-marker grey-icon'"></i>
                </button>
                <button class="charge-button" [ngClass]="task.charge ? '' : 'charge-button-adjusted'"
                        [title]="task.charge ? 'Charge' : 'No charging'" (click)="toggleChargeToCustomer(i, j)">
                  <i class="fas" [ngClass]="task.charge ? 'fa-euro-sign' : 'fa-ban red-icon'"></i>
                </button>
              </div>
            </td>
          </tr>
          <tr *ngIf="task.commentError">
            <td colspan="2" class="error-message">{{ task.commentError }}</td>
          </tr>
        </ng-container>
        </tbody>
      </table>

      <div class="sale-items-container">
        <table class="sale-items-table" *ngIf="customer.saleItems && customer.saleItems.length">
          <thead>
          <tr>
            <th>A.</th>
            <th>PPrice</th>
            <th>ProfitF</th>
            <th>SPrice</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let saleItem of customer.saleItems; let j = index">
            <tr class="sale-item-group">
              <td class="amount"><input type="number" [(ngModel)]="saleItem.amount"
                                        (ngModelChange)="onPriceChange(saleItem);" min="0" max="99.99" step="1.00"></td>
              <td class="price-width"><input type="number" [(ngModel)]="saleItem.price"
                                             (ngModelChange)="onPriceChange(saleItem);" min="0"
                                             max="999999.999999999999999999" step="0.01" placeholder="Purchase Price">
              </td>
              <td class="profit-width"><input type="number" [(ngModel)]="saleItem.profitFactor"
                                              (ngModelChange)="onProfitFactorChange(saleItem);" min="0" max="99.999999"
                                              step="0.01" placeholder="Profit Factor"></td>
              <td class="sale-price-width"><input type="number" [(ngModel)]="saleItem.salePrice"
                                                  (ngModelChange)="onSalePriceChange(saleItem);" min="0"
                                                  max="999999.999999999999999999" step="0.01" placeholder="Sale Price">
              </td>
              <td class="action-column">
                <button (click)="removeSaleItem(i, j)" title="Remove sale item">
                  <i class="fas fa-times"></i>
                </button>
              </td>
            </tr>
            <tr class="sale-item-description">
              <td colspan="4" style="border-right: none; border-bottom: none; border-top: none">
                <div class="input-with-label">
                  <span class="icon" title="Postage"><i class="fas fa-shipping-fast"></i></span>
                  <input type="number" [(ngModel)]="saleItem.postage" (ngModelChange)="onProfitFactorChange(saleItem);"
                         min="0" max="999999.999999999999999999" step="0.01" placeholder="Postage">
                  <span class="label" title="Price incl. postage per piece">&euro;</span>
                  <span class="output-text">{{ (saleItem.postagePrice | number: '1.2-2') }}</span>
                  <span class="label" title="Total price incl. postage">&sum;</span>
                  <span class="output-text">{{ (saleItem.amount * saleItem.salePrice  | number: '1.2-2') }}</span>
                </div>
              </td>
              <td class="top-aligned-button" style="border-left: none; border-bottom: none; border-top: none">
                <button *ngIf="!(saleItem.price <= 0 || saleItem.amount <= 0)" (click)="calcGrossNetNetGross(i, j)"
                        class="gross-net-button"
                        [title]="saleItem.isGrossNet ? 'Calculate net from gross' : 'Change back'">
                  <i class="fas" [ngClass]="saleItem.isGrossNet ? 'fa-arrow-down' : 'fa-arrow-up'"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="5" style="border-top: none">
                <textarea [(ngModel)]="saleItem.description" (ngModelChange)="saveState()" class="sale-description"
                          placeholder="Description"></textarea>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>

        <button class="add-sale-item-button" (click)="addSaleItem(i)" title="Add sale item"><i
          class="fas fa-shopping-cart"></i></button>
      </div>

      <div class="buttons">
        <button (click)="togglePausePlayCustomer(i)" [title]="customer.paused ? 'Play' : 'Pause'"
                [disabled]="!customer.tasks[customer.tasks.length - 1].comment || customer.tasks[customer.tasks.length - 1].comment.length < 10">
          <i class="fas" [ngClass]="customer.paused ? 'fa-play' : 'fa-pause'"></i>
        </button>
        <button (click)="stopCustomer(i)" title="Stop record"
                [disabled]="!customer.tasks[customer.tasks.length - 1].comment || customer.stopped || customer.tasks[customer.tasks.length - 1].comment.length < 10">
          <i class="fas fa-stop"></i>
        </button>
        <div class="spacer"></div>
        <button (click)="discardCustomer(i)" title="Cancel record"><i class="fas fa-times"></i></button>
        <button (click)="downloadCustomerJson(i)" title="Download JSON">
          <i class="fas fa-download"></i>
        </button>
        <button (click)="saveCustomer(i)" title="Save record to db"
                [disabled]="isRequestInProgress || !this.checkSaleItemsValidity(i) || !customer.tasks[customer.tasks.length - 1].comment || customer.tasks[customer.tasks.length - 1].comment.length < 10">
          <i class="fas fa-save"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>
