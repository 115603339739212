<div>
  <h1>Kunden To-Do Manager</h1>
  <div *ngIf="customers && customers.length">
    <!-- Dropdown, um Kunden zu filtern -->
    <label for="customer">Kunde auswählen:</label>
    <select id="customer" [(ngModel)]="selectedCustomerTag">
      <option *ngFor="let customer of customers" [value]="customer.Tag">
        {{ customer.Tag }}
      </option>
    </select>
  </div>

  <h2>To-Dos für Kunde: {{ selectedCustomerTag }}</h2>

  <!-- To-Do Liste -->
  <div *ngIf="todos.length">
    <ul>
      <li *ngFor="let todo of todos">
        <input type="checkbox" [(ngModel)]="todo.completed" />
        {{ todo.key }}: {{ todo.title }}
        <button (click)="deleteTodo(todo.key)">Löschen</button>
      </li>
    </ul>
  </div>

  <!-- Neues To-Do hinzufügen -->
  <div>
    <input
      type="text"
      placeholder="Neues To-Do"
      [(ngModel)]="newTodoTitle"
    />
    <button (click)="addTodo()">Hinzufügen</button>
  </div>
</div>
