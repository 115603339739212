import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ServiceReportComponent } from "./service-report/service-report.component";
import { HomeComponent } from "./home/home.component";
import { ProfileComponent } from "./profile/profile.component";
import { ProfileIdGuard } from "./app.routes.profile-id-guard";
import { TodoManagerComponent } from "./todo-manager/todo-manager.component";

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      roles: ['not_registered', 'customer'], // Visible only for employees
      label: 'Home',
      icon: 'home',
      tooltip: 'Go to the home page'
    }
  },
  {
    path: 'service-report',
    component: ServiceReportComponent,
    canActivate: [MsalGuard, ProfileIdGuard],
    data: {
      roles: ['employee'], // Visible only for employees
      label: 'Service Report',
      icon: 'assignment',
      tooltip: 'Create and view service reports'
    }
  },
  {
    path: 'todo',
    component: TodoManagerComponent,
    canActivate: [MsalGuard, ProfileIdGuard],
    data: {
      roles: ['customer', 'employee'], // Visible for customers and employees
      label: 'To-Do',
      icon: 'list',
      tooltip: 'Manage your to-do list'
    }
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '**',
    redirectTo: '' // Redirect unknown routes to home
  }
];
