import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {RouterModule, RouterLink, RouterOutlet, Router} from '@angular/router';
import { CommonModule } from '@angular/common';
import {Subject, filter, takeUntil, take, Observable, of, combineLatest, BehaviorSubject} from 'rxjs';
import { InteractionStatus, EventMessage, EventType, RedirectRequest } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {ProfileService} from "./services/profile.service";
import {map} from "rxjs/operators";
import {routes} from "./app.routes";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule, RouterOutlet, RouterLink, CommonModule, MatIcon, MatTooltip],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'itkern-business-manager-ui';
  isIframe = false;
  loginDisplay = false;
  private inProgress: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  menuOpen: boolean = false;
  profileIcon: string = 'account_circle';
  menuLinks$ = new BehaviorSubject<any[]>([]); // Reactive: initially empty


  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private profileService: ProfileService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.initializeMsal(); // Ensure MSAL initialization happens first

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.updateLoginDisplayStatus();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((event: EventMessage) => event.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log('Login successful:', result);
        this.updateLoginDisplayStatus();
        this.setProfileId();
      });

    of(this.profileService.profileId)
      .pipe(
        filter((id) => id !== null), // Wait for a valid profileId
        take(1) // Only take the first non-null value
      )
      .subscribe(() => {
        // Initialize menu links reactively
        this.updateMenuLinks();
      });
  }

  private navigateToFirstLink(links: any[]): void {
    if (links && links.length > 0) {
      const firstLink = links[0]; // Retrieve the first link
      this.router.navigateByUrl(firstLink.path || firstLink); // Navigate to the first link
    }
  }

  private updateMenuLinks(): void {
    this.getMenuLinks()
      .pipe(take(1)) // Take one emission from `getMenuLinks`
      .subscribe((links) => {
        this.menuLinks$.next(links); // Push new menu links to BehaviorSubject
        console.log('Menu Links:', links);

        this.navigateToFirstLink(links); // Use the new method to handle navigation
      });
  }


  menuToggle(): void {
    // Toggle the menuOpen property
    this.menuOpen = !this.menuOpen;
  }

  private initializeMsal(): void {
    console.log("Initializing MSAL and awaiting readiness...");

    this.waitForMsalInitialization()
      .then(() => {
        console.log("MSAL initialized. Handling redirect promise...");

        // Handle the redirect if applicable
        return this.authService.instance.handleRedirectPromise();
      })
      .then((result) => {
        if (result && result.account) {
          console.log('Redirect successful: setting active account.');
          this.authService.instance.setActiveAccount(result.account);
        }
        console.log("Redirect process complete.");
      })
      .catch((error) => {
        console.error("Error during MSAL initialization or redirect handling:", error);
      })
      .finally(() => {
        console.log("MSAL initialization sequence complete.");
        this.updateLoginDisplayStatus();
        this.setProfileId();
        this.updateProfileIcon();
        this.updateMenuLinks();
      });
  }

  private waitForMsalInitialization(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          take(1)
        )
        .subscribe(
          () => {
            console.log("MSAL is ready.");
            resolve();
          },
          (error) => {
            console.error("Error during MSAL initialization:", error);
            reject(error);
          }
        );
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  private updateLoginDisplayStatus(): void {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount) {
      const accounts = this.authService.instance.getAllAccounts();

      // Set active account if not already set
      if (accounts && accounts.length > 0) {
        this.authService.instance.setActiveAccount(accounts[0]);
      }
    }

    // Update login display state based on active account
    this.loginDisplay = this.authService.instance.getActiveAccount() != null;
  }

  login(): void {
    if (this.inProgress) {
      console.warn('Login already in progress. Skipping duplicate call.');
      return;
    }

    let currentInteractionStatus: InteractionStatus = InteractionStatus.None;

    this.msalBroadcastService.inProgress$
      .pipe(takeUntil(this._destroying$))
      .subscribe((status: InteractionStatus) => {
        currentInteractionStatus = status;
      });

    if (currentInteractionStatus !== InteractionStatus.None) {
      console.warn('Another interactive process is already in progress.');
      return;
    }

    this.inProgress = true;

    const authRequest = this.msalGuardConfig.authRequest
      ? { ...this.msalGuardConfig.authRequest }
      : {};

    this.authService.instance
      .loginRedirect(authRequest as RedirectRequest)
      .then(() => {
        console.log('Redirect login initiated successfully.');
      })
      .catch((error) => {
        console.error('Error during login redirect:', error);
      })
      .finally(() => {
        this.inProgress = false;
      });
  }


  logout(): void {
    this.authService.logoutRedirect();
  }

  handleMenuItemClicked(): void {
    this.menuOpen = false; // Close the menu
    this.setProfileId();
  }

  private setProfileId(): void {
    const activeAccount = this.authService.instance.getActiveAccount();
    console.log('Active account:', activeAccount); // Debug to verify
    if (activeAccount && activeAccount.idTokenClaims) {
      const profileId = activeAccount.idTokenClaims['oid']; // Assuming 'oid' holds the Profile Id
      if (profileId) {
        this.profileService.profileId = profileId; // Store it for use
        console.log('Retrieved Profile Id:', profileId);
        this.updateProfileIcon(); // Call the new method to update the icon
      } else {
        console.log('No Profile Id (oid) found in ID token claims.');
      }
    } else {
      console.log('No active account or ID token claims available.');
    }
  }

  private updateProfileIcon(): void {
    this.profileService.getProfileClassification().subscribe((classification) => {
      if (classification === 'employee') {
        this.profileIcon = 'badge'; // Icon for employee
      } else if (classification === 'customer') {
        this.profileIcon = 'person'; // Icon for customer
      } else {
        this.profileIcon = 'account_circle'; // Default icon for not registered
      }
    });
  }

  private getMenuLinks(): Observable<any[]> {
    return this.profileService.getProfileClassification().pipe(
      map((classification) =>
        routes
          .filter((route) => {
            // Ensure each route has the required `data.label` property
            if (!route.data || !route.data['label']) return false;

            const roles = route.data['roles'] ?? [];
            if (roles.length === 0) return true; // Show route if no roles defined

            // Only show the route if the user's classification matches the role
            return roles.includes(classification);
          })
          .map((route) => ({
            path: route.path,
            label: route.data?.['label'] ?? '',
            icon: route.data?.['icon'] ?? '',
            tooltip: route.data?.['tooltip'] ?? '',
          }))
      )
    );
  }



  isMenuVisible(path: string): Observable<boolean> {
    // Find the route in the routes array
    const route = routes.find(r => r.path === path);
    console.log(`Route lookup for path '${path}':`, route); // Debug output

    // If no route or its data is undefined, consider the menu item not visible
    if (!route || !route.data) {
      return of(false);
    }

    // If roles are undefined or empty, make the menu item visible to everyone
    const roles = route?.data?.['roles'] ?? [];
    if (roles.length === 0) {
      return of(true); // Menu is visible if no roles are defined
    }

    // For routes with roles, check the user's profile classification
    return this.profileService.getProfileClassification().pipe(
      map(classification => {
        console.log('User classification:', classification);
        console.log('Allowed roles:', roles);
        return roles.includes(classification);
      })
    );
  }

// Helper function to get the first visible menu item
  getFirstVisibleMenuItem(): Observable<any> {
    // Map through all menu links / routes and check visibility for each
    return combineLatest(
      routes
        .filter(route => route.data?.['label']) // Only consider routes with a label (i.e., visible menu items)
        .map(route =>
          this.isMenuVisible(route.path || '').pipe(
            map(isVisible => ({ route, isVisible }))
          )
        )
    ).pipe(
      map(visibilityList =>
        // Find the first route that is visible
        visibilityList.find(item => item.isVisible)?.route || null
      )
    );
  }



}
