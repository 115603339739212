<div class="container text-center">
  <h1>Willkommen bei IT Kern</h1>
  <p>
    Der Zugang erfolgt ausschließlich mit einem <strong>Microsoft 365 Business Account</strong>.
    Bitte lassen Sie Ihre Profil-ID registrieren.
  </p>
  <p>
    Nutzen Sie den folgenden E-Mail-Link, um Ihre Profil-ID zu registrieren:
  </p>
  <a
    [href]="'mailto:support@itkern.de?subject=Registration%20Profile-ID&body=Bitte%20registrieren%20Sie%20meine%20Profil-ID:%20' + profileService.profileId"
  >
    Profil-ID registrieren
  </a>
  <p *ngIf="profileService.profileId">
    Ihre Profil-ID: <strong>{{ profileService.profileId }}</strong>
  </p>
  <p *ngIf="!profileService.profileId">
    Ihre Profil-ID konnte nicht geladen werden. Bitte wenden Sie sich an den Support.
  </p>
</div>
